import React from 'react';
import {Button, Container, Form, Nav, Navbar, NavDropdown, Offcanvas} from "react-bootstrap";

const Header = () => {
    return (
        <header>
            <>
                {['md'].map((expand) => (
                    <Navbar key={expand} expand={expand} className="bg-body-tertiary mb-3">
                        <Container fluid>
                            <Navbar.Brand href="/"
                                style={{margin: "auto", fontSize: "larger", fontWeight: "extra-bold"}}
                            >Новое сердце</Navbar.Brand>
                            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
                            <Navbar.Offcanvas
                                id={`offcanvasNavbar-expand-${expand}`}
                                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                                placement="start"
                            >
                                <Offcanvas.Header closeButton>
                                    <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                        Тверь
                                    </Offcanvas.Title>
                                </Offcanvas.Header>
                                <Offcanvas.Body>
                                    <Nav className="justify-content-end flex-grow-1 pe-3">
                                        <Nav.Link href="#action1">Служения</Nav.Link>
                                        <Nav.Link href="#action2">Контакты</Nav.Link>
                                        <NavDropdown
                                            title="Неделя"
                                            id={`offcanvasNavbarDropdown-expand-${expand}`}
                                        >
                                            <NavDropdown.Item href="#action3">Четверг</NavDropdown.Item>
                                            <NavDropdown.Item href="#action4">
                                                Воскресенье
                                            </NavDropdown.Item>
                                            <NavDropdown.Divider />
                                            <NavDropdown.Item href="#action5">
                                                Другие дни
                                            </NavDropdown.Item>
                                        </NavDropdown>
                                    </Nav>
                                    <Form className="d-flex">
                                        <Form.Control
                                            type="search"
                                            placeholder="Поиск"
                                            className="me-2"
                                            aria-label="Search"
                                        />
                                        <Button variant="outline-success">Поиск</Button>
                                    </Form>
                                </Offcanvas.Body>
                            </Navbar.Offcanvas>
                        </Container>
                    </Navbar>
                ))}
            </>
            {/*<Navbar expand="md" className="bg-body-tertiary">*/}
            {/*    <Container>*/}
            {/*        <Navbar.Brand href="/church" style={{margin: "auto", fontWeight: "extra-bold"}}>Новое сердце</Navbar.Brand>*/}
            {/*        <Navbar.Toggle aria-controls="basic-navbar-nav"/>*/}
            {/*        <Navbar.Collapse id="basic-navbar-nav">*/}
            {/*            <Nav className="mr-auto">*/}
            {/*                <Nav.Link href="/church">Расписание</Nav.Link>*/}
            {/*                <Nav.Link href="/worship">Служения</Nav.Link>*/}
            {/*            </Nav>*/}
            {/*        </Navbar.Collapse>*/}
            {/*    </Container>*/}
            {/*</Navbar>*/}
        </header>
    );
};

export default Header;