import React, { useState, useEffect, useRef } from 'react';
import Hls from 'hls.js';
import posterImg from '../assets/posterbue.jpg';

const Stream = () => {
    const [hasStream, setHasStream] = useState(false);
    const videoRef = useRef(null);
    const [streamUrl, setStreamUrl] = useState('https://stream.novoe-serdce.ru/hls/stream.m3u8');
    const [poster, setPoster] = useState('');
    // const checkInterval = useRef(null); // Для хранения идентификатора интервала проверки


    useEffect(() => {
        const checkStream = async () => {
            try {
                const response = await fetch(streamUrl, {
                    method: 'HEAD',
                });
                if (response.ok) {
                    setHasStream(true);
                } else {
                    console.error('404 получили меняем источник');
                    setHasStream(true);
                    setStreamUrl('https://stream.novoe-serdce.ru/api/recordings/stream_10_11_2024/index.m3u8')
                }
            } catch (error) {
                console.error('Ошибка при проверке потока:', error);
                setHasStream(true);
                setStreamUrl('https://stream.novoe-serdce.ru/api/recordings/stream_10_11_2024/index.m3u8')
            }
        };

        // checkInterval.current = setInterval(checkStream, 5000); // Проверять каждые 5 секунд
        // return () => clearInterval(checkInterval.current); // Очистка интервала

        checkStream();
    }, []);

    useEffect(() => {
        if (hasStream && videoRef.current) {
            const hls = new Hls();
            hls.loadSource(streamUrl);
            hls.attachMedia(videoRef.current);
            hls.on(Hls.Events.MANIFEST_PARSED, () => {
                // Убедитесь, что видео не начинает воспроизводиться автоматически
                videoRef.current.pause();
            });

            hls.on(Hls.Events.ERROR, (event, data) => {
                if (data.fatal) {
                    console.error('Ошибка HLS:', data);
                }
            });

            return () => {
                hls.destroy();
            };
        }
    }, [hasStream]);

    const handleEnded = () => {
        // Когда видео завершится, подменяем `src` на запись
        const newSrc = 'https://stream.novoe-serdce.ru/recordings/д-м-г.flv';
        setStreamUrl(newSrc);
        setPoster(posterImg);

        // Перезагружаем видео с новым источником
        if (videoRef.current) {
            videoRef.current.load();
            // videoRef.current.play();
        }
    };

    return (
        <div>
            <h3>Воскресное богослужение 10 ноября</h3>
            {hasStream ? (
                <video
                    ref={videoRef}
                    className="video-js vjs-default-skin"
                    controls
                    preload="auto"
                    width="100%"
                    poster={posterImg}
                    onEnded={handleEnded}
                    onClick={() => {
                        if (videoRef.current) {
                            videoRef.current.play().catch((error) => {
                                console.error('Ошибка при воспроизведении:', error);
                            });
                        }
                    }}
                >
                    {/* Видео будет загружено и воспроизведено автоматически при нажатии */}
                </video>
            ) : (
                <p>Трансляция в данный момент недоступна.</p>
            )}
        </div>
    );
};

export default Stream;
