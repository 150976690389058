import {
    ABOUT_ROUTE,
    ADMIN_ROUTE,
    CHURCH_ROUTE,
    LOGIN_ROUTE,
    REGISTER_ROUTE,
    WORSHIP_ROUTE
} from "./utils/consts";
import Admin from "./pages/Admin";
import Auth from "./pages/Auth";
import Worship from "./pages/Worship";
import About from "./pages/About";
import Church from "./pages/Church";

export const authRoutes = [
    {
        path: ADMIN_ROUTE,
        Component: Admin
    },
]

export const publicRoutes = [
    {
        path: CHURCH_ROUTE,
        Component: Church
    },
    {
        path: REGISTER_ROUTE,
        Component: Auth
    },
    {
        path: LOGIN_ROUTE,
        Component: Auth
    },
    {
        path: ABOUT_ROUTE,
        Component: About
    },
    {
        path: WORSHIP_ROUTE + '/:id',
        Component: Worship
    },
]
