import React from 'react';
import {Container} from "react-bootstrap";

const Quote = () => {
    return (
        <Container className='fs-3 p-5'>
            Ибо очи Господа обозревают всю землю, чтобы поддерживать тех, чье сердце вполне предано Ему.
            <p>2-я Паралипоменон 16:9</p>
            <hr />
        </Container>
    );
};

export default Quote;