import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import logo from './logo.svg'

const Footer = () => {
    return (
        <footer>
            <Container className='bg-black'>
                <Row>
                    <Col className='text-center text-bg-dark py-3'>
                        <img src={logo} className='app-logo w-25' alt="Logo" />
                    </Col>
                </Row>
                <Row>
                    <Col className='text-start text-bg-dark py-3 fs-5'>
                        Делаем Библию доступной для всех, чтобы быть счастливыми. Сейчас
                    </Col>
                </Row>
                <Row>
                    <Col className='text-start text-bg-dark py-3 fs-6'>
                        {/*<Link></Link>*/}
                        Адреса и контакты
                    </Col>
                </Row>
                <Row>
                    <Col className='text-start text-bg-dark py-3 fs-6'>
                        Принесите больше радости в вашу жизнь с Богом
                    </Col>
                </Row>
                <Row>
                    <Col className='text-center text-bg-dark py-3'>
                        Copyright &copy; Тверь 2024
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;