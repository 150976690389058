import React from 'react';
import {Container} from "react-bootstrap";
import {Link} from "react-router-dom";

const Sunday = () => {
    return (
        <Container className='text-center fs-4'>
            <p>Молитвенное богослужение</p>
            <Link
                to='https://www.youtube.com/watch?v=CpB58u_MAGg&list=PLNtz-WbLZ7jyCrK9NS6HdJyiwM8I4wVMM'>Youtube</Link>
        </Container>
    );
};

export default Sunday;