import React from 'react';
import {Container} from "react-bootstrap";

const News = () => {
    return (
        <Container className='text-center fs-5'>
            <p>События последней недели</p>
        </Container>

    );
};

export default News;